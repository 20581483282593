import { WarningDialogComponent } from './../warning-dialog/warning-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material-components.module';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { UserMenuComponent } from '../usermenu/usermenu.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { ErrorDialogModule } from '../error-dialog/error-dialog.module';
import { InfoDialogModule } from '../info-dialog/info-dialog.module';
import { DomChangeDirective } from '../../directives/domchange.directive';
import { SideNavModule } from '../side-nav/side-nav.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@NgModule({
  declarations: [MainComponent, UserMenuComponent, DomChangeDirective],
  imports: [
    CommonModule,
    FormsModule,
    MainRoutingModule,
    MaterialModule,
    ErrorDialogModule,
    InfoDialogModule,
    SideNavModule,
    SimplebarAngularModule,
  ],
  entryComponents: [ErrorDialogComponent, InfoDialogComponent, WarningDialogComponent]
})
export class MainModule { }
