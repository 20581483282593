import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleService {
  private toggle_status:boolean=true;

  constructor() { }

  getData()
  {
    return this.toggle_status;
    
  }

  setData(value:boolean)
  {
    this.toggle_status = value;
  }
}
