import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { AuthorisationService } from "./authorisation.service";
import { AuthGroup } from "../models/authorisation.types";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    protected router: Router,
    protected authorisationService: AuthorisationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this.hasRequiredPermission(route.data["auth"]);
  }

  protected hasRequiredPermission(
    authGroup: AuthGroup
  ): Promise<boolean> | boolean {
    // If user’s permissions already retrieved from the API
    if (this.authorisationService.permissions) {
      if (authGroup) {
        return this.authorisationService.hasPermission(authGroup);
      } else {
        return this.authorisationService.hasPermission(null);
      }
    } else {
      // Otherwise, must request permissions from the API first
      const promise = new Promise<boolean>((resolve, reject) => {
        this.authorisationService
          .initialisePermissions()
          .then(() => {
            if (authGroup) {
              resolve(this.authorisationService.hasPermission(authGroup));
            } else {
              resolve(this.authorisationService.hasPermission(null));
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
      return promise;
    }
  }
}
