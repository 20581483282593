import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material-components.module';
import { ErrorDialogComponent } from './error-dialog.component';


@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,

  ]
})
export class ErrorDialogModule { }
