import { Injectable } from "@angular/core";
import { AuthGroup } from "../models/authorisation.types";
import { CdnUser, CdnUserClient, TokenResponse } from "../api.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthorisationService {
  permissions: Array<string>; // Store the actions for which this user has permission

  constructor(private userService: CdnUserClient, private router: Router) {}

  hasPermission(authGroup: AuthGroup) {
    if (
      this.permissions &&
      this.permissions.find((permission) => {
        return permission === authGroup;
      })
    ) {
      return true;
    }
    return false;
  }

  initialisePermissions() {
    try {
      return new Promise(async (resolve, reject) => {
        if (this.getUser()) {
          this.getUser().subscribe((user) => {
            if (user) {
              this.userService
                .getUserPermissions(user.kyRole)
                .subscribe((result) => {
                  this.permissions = result;
                  resolve(true);
                });
            }
          });
        }
      });
    } catch (error) {
      console.warn("Failed to find get user permissions");
      return new Promise(async (resolve, reject) => {
        this.permissions = new Array();
      });
    }
  }

  getUser() {
    try {
      return this.userService.getCdnUser(
        (JSON.parse(sessionStorage.getItem("auth-info")) as TokenResponse)
          .kyUser
      );
    } catch (error) {
      console.warn("Failed to get user");
      this.router.navigate(["/login"]);
    }
  }

  resetPermissions() {
    this.permissions = new Array();
  }
}
