<ng-container *ngIf="item.type=='subheading'; then subHeading else items"></ng-container>

<ng-template #subHeading>
  <div class="sidenav-subheading">
    <a class="subheading-name">
      <span class="subheading-badge">{{item.name}}</span>
      <span fxFlex><!-- fill space --></span>
    </a>
  </div>
</ng-template>

<ng-template #items>
  <div class="sidenav-item">
    <a class="name" matRipple  routerLinkActive="activeSideNavItem" [routerLink]="router">
      <span class="sp">{{item.name}}</span>
      <span fxFlex><!-- fill space --></span>
    </a>
  </div>
</ng-template>

