import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css'],
})
export class ErrorDialogComponent {
  title = 'Error Processing Request';
  message = 'Close to Refresh';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.message) {
      this.message = data.message;
    }
    if (data.title) {
      this.title = data.title;
    }
  }
}
