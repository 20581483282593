<div style="padding: 1rem">
  <div *ngIf="dataType == 'ScanType'">
    {{ scanType.billingCode }}
    <table class="tblScanType">
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Billing Code"
              [(ngModel)]="scanType.billingCode"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Booking Code"
              [(ngModel)]="selectedProcedureType"
            >
              <mat-option *ngFor="let p of procedureTypes" [value]="p">
                {{ p.idProcedureType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Description"
              [(ngModel)]="scanType.description"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input matInput placeholder="Group" [(ngModel)]="scanType.group" />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Item No"
              [(ngModel)]="scanType.itemNo"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select placeholder="Modality" [(ngModel)]="selectedModality">
              <mat-option *ngFor="let m of modalities" [value]="m">
                {{ m.idModality }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
    <div>
      <button color="primary" class="" (click)="submit()" mat-button>
        Save
      </button>
      <button color="primary" class="" (click)="cancel()" mat-button>
        Cancel
      </button>
    </div>
  </div>
  <div *ngIf="dataType == 'ProcedureType'">
    {{ procedureType.bookingCode }}
    <table class="tblProcedureType">
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Booking Code"
              [(ngModel)]="procedureType.idProcedureType"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Description"
              [(ngModel)]="procedureType.dsProcedureType"
            />
          </mat-form-field>
        </td>
      </tr>
    </table>
    <div>
      <button color="primary" class="" (click)="submit()" mat-button>
        Save
      </button>
      <button color="primary" class="" (click)="cancel()" mat-button>
        Cancel
      </button>
    </div>
  </div>
  <div *ngIf="dataType == 'Radiographer'" class="rad-modal">
    <table class="rad-modal">
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Last Name"
              [(ngModel)]="radiographer.lastName"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="First Name"
              [(ngModel)]="radiographer.firstName"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Provider Number"
              [(ngModel)]="radiographer.providerNo"
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Ideographic Name"
              [(ngModel)]="radiographer.ideographicName"
            />
          </mat-form-field>
        </td>
      </tr>
      <div class="active-buttons">
        <mat-label>Active</mat-label>
        <mat-checkbox [(ngModel)]="radiographer.isActive"></mat-checkbox>
      </div>
    </table>
    <div class="submit-buttons">
      <button
        type="submit"
        color="primary"
        mat-raised-button
        (click)="submit()"
        mat-button
      >
        Save
      </button>
      <button color="primary" mat-raised-button (click)="cancel()" mat-button>
        Cancel
      </button>
    </div>
  </div>
  <div *ngIf="dataType == 'BookableItem'" >
    <table class="tblProcedureType">
      <tr>
        <td [formGroup]="bookNameForm">
          <mat-form-field>
            <input
              matInput
              placeholder="Name"
              [(ngModel)]="bookableItem.name"
              formControlName="name"
              required
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Description"
              [(ngModel)]="bookableItem.description"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td  [formGroup]="modalityForm">
          <mat-form-field>
            <mat-select
              formControlName="modality"
              placeholder="Modality"
              [(ngModel)]="bookableItem.kyModality"
              required
            >
              <mat-option *ngFor="let m of modalities" [value]="m.kyModality" 
              >
                {{ m.idModality }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr [formGroup]="bookperiodForm">
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Min Booking Period"
              [(ngModel)]="bookableItem.minBookingPeriod"
              formControlName="minPeriod"
              required
            />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Max Booking Period"
              [(ngModel)]="bookableItem.maxBookingPeriod"
              formControlName="maxPeriod"
              required
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <span class="timepicker-label">Week Day Starts</span>
          <ngx-mat-timepicker
            [showSpinners]="false"
            [enableMeridian]="true"
            [(ngModel)]="bookableItem.weekdayStartsAt"
          ></ngx-mat-timepicker>
        </td>
        <td>
          <span class="timepicker-label">Week Day Stops</span>
          <ngx-mat-timepicker
            [showSpinners]="false"
            [enableMeridian]="true"
            [(ngModel)]="bookableItem.weekdayStopsAt"
          ></ngx-mat-timepicker>
        </td>
      </tr>
      <tr>
        <td>
          <span class="timepicker-label">Week End Starts</span>
          <ngx-mat-timepicker
            [showSpinners]="false"
            [enableMeridian]="true"
            [(ngModel)]="bookableItem.weekendStartsAt"
          ></ngx-mat-timepicker>
        </td>
        <td>
          <span class="timepicker-label">Week End Stops</span>
          <ngx-mat-timepicker
            [showSpinners]="false"
            [enableMeridian]="true"
            [(ngModel)]="bookableItem.weekendStopsAt"
          ></ngx-mat-timepicker>
        </td>
      </tr>
    </table>
    <div>
      <button color="primary" class="" (click)="submit()" mat-button>
        Save
      </button>
      <button color="primary" class="" (click)="cancel()" mat-button>
        Cancel
      </button>
    </div>
  </div>
  <div *ngIf="dataType == 'User'" [formGroup]="userInformationForm">
    <table class="tblProcedureType">
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Last Name"
              formControlName="lastName"
              [(ngModel)]="user.lastName"
            />
            <mat-error
              *ngIf="userInformationForm.get('lastName').hasError('required')"
            >
              Last name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="First Name"
              formControlName="firstName"
              [(ngModel)]="user.firstName"
            />
            <mat-error
              *ngIf="userInformationForm.get('firstName').hasError('required')"
            >
              First Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Username"
              formControlName="userName"
              [(ngModel)]="user.userName"
            />
            <mat-error
              *ngIf="userInformationForm.get('userName').hasError('required')"
            >
              Username is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Role"
              formControlName="role"
              [(ngModel)]="user.kyRole"
            >
              <mat-option *ngFor="let p of roles" [value]="p.kyRole">
                {{ p.name }}
              </mat-option>
            </mat-select>
            <mat-error> Role is <strong>required</strong> </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Position"
              formControlName="position"
              [(ngModel)]="user.kyPosition"
            >
              <mat-option *ngFor="let p of positions" [value]="p.kyPosition">
                {{ p.name }}
              </mat-option>
            </mat-select>
            <mat-error> Position is <strong>required</strong> </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Status"
              formControlName="status"
              [(ngModel)]="user.kyStatus"
            >
              <mat-option *ngFor="let s of userStatuses" [value]="s.kyStatus">
                {{ s.name }}
              </mat-option>
            </mat-select>
            <mat-error> Status is <strong>required</strong> </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Phone Number"
              formControlName="phoneNo"
              [(ngModel)]="user.phone"
            />
            <mat-error> Please enter valid phone number. </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Mobile Number"
              formControlName="mobileNo"
              [(ngModel)]="user.mobile"
            />
            <mat-error> Please enter valid mobile number. </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <input
              matInput
              placeholder="Fax"
              formControlName="fax"
              [(ngModel)]="user.fax"
            />
            <mat-error> Please enter valid fax number. </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              autocomplete="off"
              matInput
              placeholder="Email"
              formControlName="email"
              [(ngModel)]="user.email"
            />
            <mat-error> Please enter valid email. </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Preferred Contact"
              formControlName="contact"
              [(ngModel)]="user.preferredContact"
            >
              <mat-option [value]="'Phone Number'"> Phone Number </mat-option>
              <mat-option [value]="'Email'"> Email </mat-option>
            </mat-select>
            <mat-error
              *ngIf="userInformationForm.get('contact').hasError('required')"
            >
              Contact is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Default Practice"
              formControlName="practice"
              [(ngModel)]="user.kyPractice"
            >
              <mat-option *ngFor="let p of practices" [value]="p.kyPractice">
                {{ p.name }}
              </mat-option>
            </mat-select>
            <mat-error> Practice is <strong>required</strong> </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <mat-select
              placeholder="Practices"
              [formControl]="practicesControl"
              (selectionChange)="setPractices($event)"
              multiple
            >
              <mat-option *ngFor="let p of practices" [value]="p.kyPractice">{{
                p.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input
              autocomplete="off"
              type="password"
              matInput
              placeholder="Password"
              [(ngModel)]="user.password"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </td>
      </tr>
    </table>
    <div>
      <button color="primary" class="" (click)="submit()" mat-button>
        Save
      </button>
      <button color="primary" class="" (click)="cancel()" mat-button>
        Cancel
      </button>
    </div>
  </div>
</div>
