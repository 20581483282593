import { Setting, SettingClient } from './../api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class SettingsService {

    private settings: Setting[];

    constructor(private m_settingsClient: SettingClient) {
        this.settings = [];
    }

    clear() {
        this.settings = [];
    }

    updateLocalSetting(s: Setting): void {
        const setting = this.settings?.find(e => e.dsSetting === s.dsSetting);
        if (setting) {
            const index = this.settings.indexOf(setting, 0);
            if (index > -1) {
                this.settings.splice(index, 1);
            }
        }
        this.settings.push(s);
    }

    getSettings(): Observable<Setting[]> {
        if (this.settings !== undefined && this.settings.length > 0) {
            return new Observable<Setting[]>(sub => {
                sub.next(this.settings);
                sub.complete();
            });
        } else {
            const settings = this.m_settingsClient.getAllWeb().pipe(tap(result => {
                this.settings = result;
            }));
            return settings;
        }
    }

    getSettingByDescription(dsSetting: string): Observable<Setting> {
        try{
             const setting = this.settings?.find(e => e.dsSetting === dsSetting);
            if (setting) {
                return new Observable<Setting>(sub => {
                    sub.next(setting);
                    sub.complete();
                });
            } else {
                return this.m_settingsClient.getSettingByDescription(dsSetting).pipe(tap(result => {
                    this.settings.push(result);
                }));
            }
        } catch {
            console.warn("Failed to find setting: " + dsSetting);
            return new Observable<Setting>(sub => {
                sub.next(undefined);
                sub.complete();
            });
        }       
    }
}
