import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./components/main/main.component";
import { AuthGuardService } from "./services/auth-guard.service";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./components/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "dailyreport",
    loadChildren: () =>
      import("./components/printing/dailyreport/daily-report.module").then(
        (m) => m.DailyReportModule
      ),
  },
  {
    path: "referrallabel",
    loadChildren: () =>
      import("./components/printing/referral-label/referral-label.module").then(
        (m) => m.ReferralLabelModule
      ),
  },
  {
    path: "baglabel",
    loadChildren: () =>
      import("./components/printing/bag-label/bag-label.module").then(
        (m) => m.BagLabelModule
      ),
  },
  {
    path: "db4form",
    loadChildren: () =>
      import("./components/printing/db4-form/db4-form.module").then(
        (m) => m.DB4FormModule
      ),
  },
  {
    path: "referralform/emr/:kyEmr",
    loadChildren: () =>
      import("./components/referral-form/referral-form.module").then(
        (m) => m.ReferralFormModule
      ),
  },
  {
    path: "referralform/booking/:kyBooking",
    loadChildren: () =>
      import("./components/referral-form/referral-form.module").then(
        (m) => m.ReferralFormModule
      ),
  },
  {
    path: "referralform/external/:acc/:secret",
    loadChildren: () =>
      import("./components/referral-form/referral-form.module").then(
        (m) => m.ReferralFormModule
      ),
  },
  {
    path: "main",
    component: MainComponent,
    children: [
      {
        path: "viewbookings",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_VIEW_BOOKINGS_PAGE" },
        loadChildren: () =>
          import("./components/view-bookings/view-bookings.module").then(
            (m) => m.ViewBookingsModule
          ),
        // tslint:disable-next-line:max-line-length
        // loadChildren: () => import('./components/view-bookings-advanced/view-bookings-advanced.module').then(m => m.ViewBookingsAdvancedModule)
      },
      {
        path: "searchbookings",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_SEARCH_BOOKINGS_PAGE" },
        loadChildren: () =>
          import("./components/search-bookings/search-bookings.module").then(
            (m) => m.SearchBookingsModule
          ),
      },
      {
        path: "waitingroom",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_WAITING_ROOM_PAGE" },
        loadChildren: () =>
          import("./components/waiting-room/waiting-room.module").then(
            (m) => m.WaitingRoomModule
          ),
      },
      {
        path: "findtimeslot",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_FIND_TIME_SLOT_PAGE" },
        loadChildren: () =>
          import("./components/find-time-slot/find-time-slot.module").then(
            (m) => m.FindTimeSlotModule
          ),
      },
      {
        path: "health-funds",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_HEALTH_FUND_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/health-funds/health-funds.module"
          ).then((m) => m.HealthFundsModule),
      },
      {
        path: "patient",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_PATIENT_PAGE" },
        loadChildren: () =>
          import("./components/administrator/patient/patient.module").then(
            (m) => m.PatientModule
          ),
      },
      {
        path: "proceduretype",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_PROCEDURE_TYPE_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/proceduretype/proceduretype.module"
          ).then((m) => m.ProcedureTypeModule),
      },
      {
        path: "scantype",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_SCAN_TYPE_PAGE" },
        loadChildren: () =>
          import("./components/administrator/scantype/scantype.module").then(
            (m) => m.ScanTypeModule
          ),
      },
      {
        path: "ward",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_WARD_PAGE" },
        loadChildren: () =>
          import("./components/administrator/ward/wards.module").then(
            (m) => m.WardsModule
          ),
      },
      {
        path: "radiographer",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_RADIOGRAPHER_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/radiographer/radiographer.module"
          ).then((m) => m.RadiographerModule),
      },
      {
        path: "referrer",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_REFERRER_PAGE" },
        loadChildren: () =>
          import("./components/administrator/referrer/referrer.module").then(
            (m) => m.ReferrerModule
          ),
      },
      {
        path: "broadcastque",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_BROADCAST_QUEUE_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/broadcast/broadcast-que.module"
          ).then((m) => m.BroadcastQueModule),
      },
      {
        path: "broadcastdest",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_BROADCAST_DESTS_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/broadcast-dests/broadcast-dest.module"
          ).then((m) => m.BroadcastDestModule),
      },
      {
        path: "broadcastrules",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_BROADCAST_RULES_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/broadcast-rules/broadcast-rules.module"
          ).then((m) => m.BroadcastRulesModule),
      },
      {
        path: "configuration",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_CONFIGURATION_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/configuration/configuration.module"
          ).then((m) => m.ConfigurationModule),
      },
      {
        path: "sitesettings",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_CONFIGURATION_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/site-settings/site-settings.module"
          ).then((m) => m.SiteSettingsModule),
      },
      {
        path: "referralformjh",
        loadChildren: () =>
          import("./components/referral-form/referral-form-jh.module").then(
            (m) => m.ReferralFormJHModule
          ),
      },
      {
        path: "practice",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_PRACTICE_PAGE" },
        loadChildren: () =>
          import("./components/administrator/practice/practice.module").then(
            (m) => m.PracticeModule
          ),
      },
      {
        path: "room",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_ROOM_PAGE" },
        loadChildren: () =>
          import("./components/administrator/room/room.module").then(
            (m) => m.RoomModule
          ),
      },
      {
        path: "modality",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_MODALITY_PAGE" },
        loadChildren: () =>
          import("./components/administrator/modality/modality.module").then(
            (m) => m.ModalityModule
          ),
      },
      {
        path: "user",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_USER_PAGE" },
        loadChildren: () =>
          import("./components/administrator/user/user.module").then(
            (m) => m.UserModule
          ),
      },
      {
        path: "person",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_PERSON_PAGE" },
        loadChildren: () =>
          import("./components/administrator/person/persons.module").then(
            (m) => m.PersonsModule
          ),
      },
      {
        path: "pbrc",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_PBRC_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/pbrcCustomBilling/pbrc.module"
          ).then((m) => m.PbrcModule),
      },
      {
        path: "patient-merge",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_PATIENT_MERGE_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/patientMerge/patientMerge.module"
          ).then((m) => m.PatientMergeModule),
      },
      {
        path: "time-restrictions",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_TIME_RESTRICTIONS_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/timerestrictions/timeRestrictions.module"
          ).then((m) => m.TimeRestrictionsModule),
      },
      {
        path: "resendbilling",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_RESEND_BILLING_PAGE" },
        loadChildren: () =>
          import(
            "./components/administrator/resend-billing/resend-billing.module"
          ).then((m) => m.ResendBillingModule),
      },
      {
        path: "reports",
        canActivate: [AuthGuardService],
        data: { auth: "CAN_VIEW_REPORT_PAGE" },
        loadChildren: () =>
          import("./components/administrator/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: "sandbox",
        loadChildren: () =>
          import("./components/sandbox/sandbox.module").then(
            (m) => m.SandboxModule
          ),
      },
    ],
  },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
