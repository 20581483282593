import { Injectable } from '@angular/core';
import { Booking, TimeSlot } from './api.service';
import { TimeSlotComponent } from './components/timeslot/timeslot.component';


@Injectable()
export class DateHelperService {
  convertDate(dateTime: Date): string {
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();

    const date = [year, month, day].join('-');
    return date;
  }

  convertDateToSql(dateTime: Date): any {
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();
    const retVal = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':00';
    return retVal;
  }

  convertDateToSql2(dateTime: Date): any {
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();
    const retVal = year + '-' +
    (month < 9 ? '0' + month : month) + '-' +
    (day < 9 ? '0' + day : day) + 'T' +
    (hour < 9 ? '0' + hour : hour) + ':' +
    (minute < 9 ? '0' + minute : minute) + ':00.000Z';
    return retVal;
  }

  GetDayArray(d: Date): Array<Day> {
    var date = new Date(d);

    var dayInc = date.setDate(date.getDate());
    while (date.getDay() !== 0) {
      dayInc = date.setDate(date.getDate() - 1);
    }
    var array = new Array<Day>();
    var day = new Day();
    day.index = 0;
    day.name = 'Sunday';
    day.date = new Date(dayInc);
    array.push(day);
    day = new Day();
    day.index = 1;
    day.name = 'Monday';
    dayInc = date.setDate(date.getDate() + 1);
    day.date = new Date(dayInc);
    array.push(day);
    day = new Day();
    day.index = 2;
    day.name = 'Tuesday';
    dayInc = date.setDate(date.getDate() + 1);
    day.date = new Date(dayInc);
    array.push(day);
    day = new Day();
    day.index = 3;
    day.name = 'Wednesday';
    dayInc = date.setDate(date.getDate() + 1);
    day.date = new Date(dayInc);
    array.push(day);
    day = new Day();
    day.index = 4;
    day.name = 'Thursday';
    dayInc = date.setDate(date.getDate() + 1);
    day.date = new Date(dayInc);
    array.push(day);
    day = new Day();
    day.index = 5;
    day.name = 'Friday';
    dayInc = date.setDate(date.getDate() + 1);
    day.date = new Date(dayInc);
    array.push(day);
    day = new Day();
    day.index = 6;
    day.name = 'Saturday';
    dayInc = date.setDate(date.getDate() + 1);
    day.date = new Date(dayInc);
    array.push(day);
    return array;
  }

}

export class TimeSlotHour {
  date: Date;
  hour: number;
  minutes: Array<TimeSlotMinute> = new Array<TimeSlotMinute>();
}

export class TimeSlotMinute {
  hour: number;
  minute: number;
  bookings: Array<Booking> = new Array<Booking>();
  afterHours: boolean;
  ts: TimeSlotComponent;
  unavailabilityReason: String = '';
}

export class TimeHour {
  hour: number;
  minutes: Array<TimeMinute> = new Array<TimeMinute>();
  ampm: string;
}

export class TimeMinute {
  minute: number;
  displayMinute: string;
  availability: boolean;
}

export class Day {
  index: number;
  name: string;
  date: Date;
}

