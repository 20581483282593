<button class="accountIcon" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>account_box</mat-icon>
</button>
<span class="spUser">{{currentUser.name}}</span>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="bookings">Bookings</button>
  <button mat-menu-item [matMenuTriggerFor]="administrator">Administrator</button>
  <button mat-menu-item (click)="logout()">Logout</button>
</mat-menu>
<mat-menu #bookings="matMenu">
  <button mat-menu-item (click)="setMenuItem('viewbookings')">View Bookings</button>
  <button mat-menu-item (click)="setMenuItem('searchbookings')">Search Bookings</button>
  <button mat-menu-item (click)="setMenuItem('waitingroom')">Waiting Room</button>
</mat-menu>
<mat-menu #administrator="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="booking">Booking</button>
  <button mat-menu-item (click)="setMenuItem('patient')">Patient</button>
  <button mat-menu-item [matMenuTriggerFor]="system">System Utilities</button>
</mat-menu>
<mat-menu #booking="matMenu">
  <button mat-menu-item (click)="setMenuItem('proceduretype')">Procedure Type</button>
  <button mat-menu-item (click)="setMenuItem('scantype')">Scan Type</button>
  <button mat-menu-item (click)="setMenuItem('radiographer')">Radiographer</button>
  <button mat-menu-item (click)="setMenuItem('referrer')">Referrer</button>
  <button mat-menu-item (click)="setMenuItem('practice')">Practice</button>
  <button mat-menu-item (click)="setMenuItem('room')">Room</button>
</mat-menu>
<mat-menu #system="matMenu">
  <button mat-menu-item (click)="setMenuItem('broadcastque')">Broadcast Que</button>
  <button mat-menu-item (click)="setMenuItem('configuration')">Configuration</button>
</mat-menu>
