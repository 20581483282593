import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css'],
})
export class InfoDialogComponent {
  title = 'Info';
  messages = [];
  message = '';
  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.message) {
      this.message = data.message;
    }
    if (data.messages) {
      this.messages = data.messages;
    }
    if (data.title) {
      this.title = data.title;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
