import { BookingStatus, CancellationReason, StatusClient, Priority, PriorityClient,
    BillingMethod, BillingMethodClient, Ward, WardClient, BookingCenter, TokenResponse, BookingCenterClient, PracticeClient, Practice } from './api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ObjectsService {

    constructor(private m_statusService: StatusClient, private m_priorityService: PriorityClient,
        private m_BillingMethodService: BillingMethodClient, private m_wardService: WardClient,
        private m_practiceService: PracticeClient, private m_BookingCentreService: BookingCenterClient) {
    }

    private statuses: BookingStatus[];
    private practices: Practice[];
    private cancellations: CancellationReason[];
    private priority: Priority[];
    private billingMethods: BillingMethod[];
    private wards: Map<number, Ward[]>;
    private bookingCenters: Map<number, BookingCenter[]>;

    clear() {
        this.statuses = [];
        this.practices = [];
        this.cancellations = [];
        this.priority = [];
        this.billingMethods = [];
        this.wards = new Map<number, Ward[]>();
        this.bookingCenters = new Map<number, BookingCenter[]>();
    }

    preload(): void {
        const user = JSON.parse(localStorage.getItem('auth-info')) as TokenResponse;
        this.getPriority();
        this.getStatus();
        this.getPractices();
        this.getCancellationReasons();
        this.getBillingMethods();
        this.getWardsByKyPractice(user.kyPractice);
        this.getBookingCentersByKyPractice(user.kyPractice);
    }

    getPractices(): Observable<Practice[]> {
        if (this.practices !== undefined && this.practices.length > 0) {
            return new Observable<Practice[]>(sub => {
                sub.next(this.practices);
                sub.complete();
            });
        } else {
            const practices = this.m_practiceService.getAll();
            practices.subscribe(result => {
                this.practices = result;
            });
            return practices;
        }
    }

    getBookingCentersByKyPractice(kyPractice: number): Observable<BookingCenter[]> {
        if (this.bookingCenters !== undefined && this.bookingCenters.has(kyPractice)) {
            return new Observable<BookingCenter[]>(sub => {
                sub.next(this.bookingCenters.get(kyPractice));
                sub.complete();
            });
        } else {
            const bookingCenters = this.m_BookingCentreService.getBookingCentersByPractice(kyPractice);
            bookingCenters.subscribe(result => {
                if (!this.bookingCenters) {
                    this.bookingCenters = new Map<number, BookingCenter[]>();
                }
                this.bookingCenters.set(kyPractice, result);
            });
            return bookingCenters;
        }
    }

    getWardsByKyPractice(kyPractice: number): Observable<Ward[]> {
        if (this.wards !== undefined && this.wards.has(kyPractice)) {
            return new Observable<Ward[]>(sub => {
                sub.next(this.wards.get(kyPractice));
                sub.complete();
            });
        } else {
            const wards = this.m_wardService.getWardByPractice(kyPractice);
            wards.subscribe(result => {
                if (!this.wards) {
                    this.wards = new Map<number, Ward[]>();
                }
                this.wards.set(kyPractice, result);
            });
            return wards;
        }
    }

    getPriority(): Observable<Priority[]> {
        if (this.priority !== undefined && this.priority.length > 0) {
            return new Observable<Priority[]>(sub => {
                sub.next(this.priority);
                sub.complete();
            });
        } else {
            const priority = this.m_priorityService.getAll();
            priority.subscribe(result => {
                this.priority = result;
            });
            return priority;
        }
    }
    getStatus(): Observable<BookingStatus[]> {
        if (this.statuses !== undefined && this.statuses.length > 0) {
            return new Observable<BookingStatus[]>(sub => {
                sub.next(this.statuses);
                sub.complete();
            });
        } else {
            const status = this.m_statusService.getBookingStatus();
            status.subscribe(result => {
                this.statuses = result;
            });
            return status;
        }
    }
    getCancellationReasons(): Observable<CancellationReason[]> {
        if (this.cancellations !== undefined && this.cancellations.length > 0) {
            return new Observable<CancellationReason[]>(sub => {
                sub.next(this.cancellations);
                sub.complete();
            });
        } else {
            const cancellations = this.m_statusService.getCancellationReasons();
            cancellations.subscribe(result => {
                this.cancellations = result;
            });
            return cancellations;
        }
    }

    getBillingMethods(): Observable<BillingMethod[]> {
        if (this.billingMethods !== undefined && this.billingMethods.length > 0) {
            return new Observable<BillingMethod[]>(sub => {
                sub.next(this.billingMethods);
                sub.complete();
            });
        } else {
            const billingMethods = this.m_BillingMethodService.getAll();
            billingMethods.subscribe(result => {
                this.billingMethods = result;
            });
            return billingMethods;
        }
    }
}
