import {
  Modality,
  PracticeClient,
  ScanTypeClient,
  ModalityClient,
  PositionClient,
  Position,
  CdnRoleClient,
  CdnRole,
  CdnUserClient,
  CdnUser,
  Status,
  StatusClient,
} from "./../../../api.service";
import { Component, Inject, OnInit } from "@angular/core";
import {
  BookableItem,
  BookableItemClient,
  RadiographerClient,
  Radiographer,
  ProcedureTypeClient,
  ProcedureType,
  ScanType,
  Practice,
} from "../../../api.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from "@angular/forms";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "editContentDialog",
  templateUrl: "./editContent-dialog.component.html",
  styleUrls: ["./editContent-dialog.component.css"],
})
export class EditContentDialogComponent implements OnInit {
  dataType: string;
  editableContent = "";
  positionX: any;
  positionY: any;

  private m_procedureTypes: Array<ProcedureType>;
  get procedureTypes(): Array<ProcedureType> {
    return this.m_procedureTypes;
  }
  set procedureTypes(value: Array<ProcedureType>) {
    this.m_procedureTypes = value;
  }

  private m_selectedPractice: Practice;
  get selectedPractice(): Practice {
    return this.m_selectedPractice;
  }
  set selectedPractice(value: Practice) {
    this.m_selectedPractice = value;
  }

  private m_selectedProcedureType: ProcedureType;
  get selectedProcedureType(): ProcedureType {
    return this.m_selectedProcedureType;
  }
  set selectedProcedureType(value: ProcedureType) {
    this.m_selectedProcedureType = value;
  }

  private m_userStatuses: Array<Status>;
  get userStatuses(): Array<Status> {
    return this.m_userStatuses;
  }
  set userStatuses(value: Array<Status>) {
    this.m_userStatuses = value;
  }

  private m_positions: Array<Position>;
  get positions(): Array<Position> {
    return this.m_positions;
  }
  set positions(value: Array<Position>) {
    this.m_positions = value;
  }

  private m_practices: Array<Practice>;
  get practices(): Array<Practice> {
    return this.m_practices;
  }
  set practices(value: Array<Practice>) {
    this.m_practices = value;
  }

  private m_roles: Array<CdnRole>;
  get roles(): Array<CdnRole> {
    return this.m_roles;
  }
  set roles(value: Array<CdnRole>) {
    this.m_roles = value;
  }

  private m_modalities: Array<Modality>;
  get modalities(): Array<Modality> {
    return this.m_modalities;
  }
  set modalities(value: Array<Modality>) {
    this.m_modalities = value;
  }

  private m_selectedModality: Modality;
  get selectedModality(): Modality {
    return this.m_selectedModality;
  }
  set selectedModality(value: Modality) {
    this.m_selectedModality = value;
  }

  private m_kyPractices: Array<number>;
  get selectedKyPractices(): Array<number> {
    return this.m_kyPractices;
  }
  set selectedKyPractices(value: Array<number>) {
    this.m_kyPractices = value;
  }

  private m_scanType: ScanType;
  get scanType(): ScanType {
    return this.m_scanType;
  }
  set scanType(value: ScanType) {
    this.m_scanType = value;
  }

  private m_procedureType: ProcedureType;
  get procedureType(): ProcedureType {
    return this.m_procedureType;
  }
  set procedureType(value: ProcedureType) {
    this.m_procedureType = value;
  }

  private m_radiographer: Radiographer;
  get radiographer(): Radiographer {
    return this.m_radiographer;
  }
  set radiographer(value: Radiographer) {
    this.m_radiographer = value;
  }

  private m_bookableItem: BookableItem;
  get bookableItem(): BookableItem {
    return this.m_bookableItem;
  }
  set bookableItem(value: BookableItem) {
    this.m_bookableItem = value;
  }

  private m_user: CdnUser;
  get user(): CdnUser {
    return this.m_user;
  }
  set user(value: CdnUser) {
    this.m_user = value;
  }
  constructor(
    public dialogRef: MatDialogRef<EditContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private m_modalityService: ModalityClient,
    private m_scanTypeService: ScanTypeClient,
    private m_procedureTypeService: ProcedureTypeClient,
    private m_radiographerService: RadiographerClient,
    private m_bookableItemService: BookableItemClient,
    private m_userService: CdnUserClient,
    private m_roleService: CdnRoleClient,
    private m_positionService: PositionClient,
    private m_practiceService: PracticeClient,
    private m_userStatusService: StatusClient,
    private snackBar: MatSnackBar
  ) {}
  private formBuilder: FormBuilder = new FormBuilder();
  userInformationForm: FormGroup = this.formBuilder.group({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    userName: new FormControl("", [Validators.required]),
    role: new FormControl("", [Validators.required]),
    position: new FormControl("", [Validators.pattern("^[1-9][0-9]*$")]),
    status: new FormControl("", [Validators.pattern("^[1-9][0-9]*$")]),
    phoneNo: new FormControl("", [Validators.pattern("^[0-9]{5,10}$")]),
    mobileNo: new FormControl("", [Validators.pattern("^[0-9]{5,10}$")]),
    fax: new FormControl("", [Validators.pattern("^[0-9]{5,10}$")]),
    email: new FormControl("", [Validators.required, Validators.email]),
    contact: new FormControl("", [Validators.required]),
    practice: new FormControl("", [Validators.pattern("^[1-9][0-9]*$")]),
  });
  //modality validator
  private formBuilder2: FormBuilder = new FormBuilder();
  modalityForm: FormGroup = this.formBuilder2.group({
    modality: new FormControl("", [Validators.required]),
  });
  //period validator
  private formBuilder3: FormBuilder = new FormBuilder();
  bookperiodForm: FormGroup = this.formBuilder3.group({
    minPeriod: new FormControl("", [Validators.required]),
    maxPeriod: new FormControl("", [Validators.required]),
  });

  //book name
  private formBuilder4: FormBuilder = new FormBuilder();
  bookNameForm: FormGroup = this.formBuilder4.group({
    name: new FormControl("", [Validators.required]),
  });

  practicesControl = new FormControl();

  ngOnInit() {
    this.bindData();
  }

  validateForm(): boolean {
    switch (this.dataType) {
      case "ScanType":
        break;
      case "ProcedureType":
        break;
      case "Radiographer":
        break;
      case "User":
        this.userInformationForm.get("firstName").markAsTouched();
        this.userInformationForm.get("lastName").markAsTouched();
        this.userInformationForm.get("userName").markAsTouched();
        this.userInformationForm.get("role").markAsTouched();
        this.userInformationForm.get("position").markAsTouched();
        this.userInformationForm.get("status").markAsTouched();
        this.userInformationForm.get("phoneNo").markAsTouched();
        this.userInformationForm.get("mobileNo").markAsTouched();
        this.userInformationForm.get("fax").markAsTouched();
        this.userInformationForm.get("email").markAsTouched();
        this.userInformationForm.get("contact").markAsTouched();
        this.userInformationForm.get("practice").markAsTouched();
        if (this.userInformationForm.status === "VALID") {
          return true;
        }
        return false;
      case "BookableItem":
        let j_modality = this.modalityForm.get("modality").hasError("required");
        let j_min = this.bookperiodForm.get("minPeriod").hasError("required");
        let j_max = this.bookperiodForm.get("maxPeriod").hasError("required");
        let j_name = this.bookNameForm.get("name").hasError("required");
        if (j_name || j_min || j_modality || j_max) {
          return false;
        }
        if (this.m_bookableItem.description === null) {
          this.m_bookableItem.description = " ";
        }
        return true;

      default:
        break;
    }
    return true;
  }

  async bindData() {
    this.dataType = this.data.dataType;
    switch (this.dataType) {
      case "ScanType":
        this.loadProcedureTypes();
        if (this.data.m_data !== null) {
          this.m_scanType = this.data.m_data;
        } else {
          this.m_scanType = new ScanType();
        }
        this.bindModality();
        break;
      case "ProcedureType":
        this.m_procedureType = this.data.m_data.clone();
        break;
      case "Radiographer":
        this.m_radiographer = this.data.m_data.clone();
        break;
      case "User":
        if (this.data.m_data !== null) {
          this.m_user = new CdnUser(this.data.m_data.toJSON());
        } else {
          this.m_user = new CdnUser();
        }
        this.bindRoles();
        this.bindPositions();
        this.bindUserStatus();
        this.bindPractices();
        this.bindSelectedPractices(this.user.kyUser);
        break;
      case "BookableItem":
        this.bindModality();
        this.m_bookableItem = new BookableItem(this.data.m_data);
        break;
      default:
        break;
    }
  }

  async loadProcedureTypes() {
    this.m_procedureTypes = new Array<ProcedureType>();
    const result = await this.m_procedureTypeService.getAll().subscribe((e) => {
      if (result) {
        e.forEach((element) => {
          const pt = new ProcedureType(element);
          this.m_procedureTypes.push(pt);
          if (
            this.data.m_data.procedureType.kyProcedureType ===
            pt.kyProcedureType
          ) {
            this.selectedProcedureType = pt;
          }
        });
      }
    });
  }

  bindPractices() {
    this.m_practices = new Array<Practice>();
    this.m_practiceService.getAll().subscribe((json: Object) => {
      if (json) {
        // tslint:disable-next-line:forin
        for (const m in json) {
          const e = Practice.fromJS(json[m]);
          this.m_practices.push(e);
        }
      }
    });
  }

  bindUserStatus() {
    this.m_userStatusService.getAll().subscribe((result) => {
      if (result) {
        this.m_userStatuses = result;
      }
    });
  }

  bindPositions() {
    this.m_positionService.getAll().subscribe((result) => {
      if (result) {
        this.m_positions = result;
      }
    });
  }

  bindRoles() {
    this.m_roles = new Array<CdnRole>();
    this.m_roleService.getAll().subscribe((result) => {
      if (result) {
        this.m_roles = result;
      }
    });
  }

  bindModality() {
    this.m_modalities = new Array<Modality>();
    this.m_modalityService.getModalities().subscribe((json: Object) => {
      if (json) {
        // tslint:disable-next-line:forin
        for (const m in json) {
          const modality = Modality.fromJS(json[m]);
          this.m_modalities.push(modality);
        }
        this.m_selectedModality = this.m_modalities.find(
          (modality) => modality.kyModality === this.data.m_data.kyModality
        );
      }
    });
  }

  bindSelectedPractices(kyUser) {
    this.m_kyPractices = new Array<number>();
    if (kyUser !== 0) {
      this.m_practiceService
        .getPracticeByUser(kyUser)
        .subscribe((json: Object) => {
          if (json) {
            // tslint:disable-next-line:forin
            for (const m in json) {
              const e = Practice.fromJS(json[m]);
              this.selectedKyPractices.push(e.kyPractice);
            }
            this.practicesControl.setValue(this.selectedKyPractices);
          }
        });
    }
  }

  setPractices(event) {
    this.selectedKyPractices = event.value;
  }

  async submit() {
    if (!this.validateForm()) {
      return;
    }
    switch (this.dataType) {
      case "ScanType":
        this.m_scanType.modality = this.m_selectedModality.idModality.toString();
        // TODO FIX procedureType (api service)
        // this.m_scanType.procedureType = this.m_selectedProcedureType;
        this.m_scanTypeService.put(this.m_scanType);
        break;
      case "ProcedureType":
        this.m_procedureTypeService.put(this.m_procedureType).subscribe();
        break;
      case "Radiographer":
        this.m_radiographerService.put(this.m_radiographer).subscribe();
        break;
      case "BookableItem":
        //something wrong for the week end time, but I dont know the reason, so I use expression below to "fix" it
       this.m_bookableItem.weekendStartsAt = this.plusHours(10,this.m_bookableItem.weekendStartsAt);
       this.m_bookableItem.weekendStopsAt = this.plusHours(10,this.m_bookableItem.weekendStopsAt);

        this.m_bookableItemService
          .putBookableItem(this.m_bookableItem)
          .subscribe((bi) => {
            this.dialogRef.close(bi);
          });
        break;
      case "User":
        this.m_userService.put(this.user).subscribe(
          (data) => {
            const user = new CdnUser(data);
            this.m_userService
              .putUserPractices(user.kyUser, this.selectedKyPractices)
              .subscribe((e) => {
                this.dialogRef.close(user);
              });
          },
          () => {
            this.snackBar.open("An Error Occurred");
            this.dialogRef.close();
          }
        );
        return;
      default:
        break;
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  plusHours(numOfHours,  date:Date) {
    date.setHours(date.getHours() + numOfHours);
    return date;
  }
  
}
