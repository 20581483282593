import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.css'],
})
export class WarningDialogComponent {
  title = 'Warning';
  message = '';
  constructor(public dialogRef: MatDialogRef<WarningDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.message) {
      this.message = data.message;
    }
    if (data.title) {
      this.title = data.title;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  continue() {
    this.dialogRef.close(true);
  }
}
