<div class="container">
    <span class="title">
      {{title}}
    </span>
    <div class="message"> 
      <span>{{message}}</span>
  </div>  
  <button color="primary" mat-raised-button (click)="cancel()">Cancel</button>
  <button color="primary" mat-raised-button (click)="continue()">Continue</button>
</div>
