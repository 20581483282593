import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav.component';
import { MaterialModule } from '../../shared/material-components.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component';
import { SideNavService } from './side-nav.service';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [SideNavComponent, SideNavItemComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SimplebarAngularModule,
    RouterModule
  ],
  exports: [SideNavComponent],
  providers: [SideNavService],
})
export class SideNavModule {

}
