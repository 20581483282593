import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import "rxjs/add/operator/map";
import { TokenResponse } from "./api.service";
import { AuthorisationService } from "../app/services/authorisation.service";

@Injectable()
export class AuthenticationService {
  constructor(
    private router: Router,
    protected authorisationService: AuthorisationService
  ) {}

  logout(): void {
    // if refresh tokens were ever implemented, this is where you could fire the token revoke
    // clear token and remove data from local storage to log user out
    localStorage.removeItem("currentDate");
    localStorage.removeItem("auth-info");
    sessionStorage.removeItem("auth-info");
    this.authorisationService.initialisePermissions();
  }

  checkExternal(): void {
    if (localStorage.getItem("auth-info")) {
      const user = JSON.parse(
        localStorage.getItem("auth-info")
      ) as TokenResponse;
      if (user.username === "External") {
        localStorage.clear();
        this.router.navigate(["/login"]);
      }
    }
  }
}
