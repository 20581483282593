<div class="container">
    <span class="title">
      {{title}}
    </span>
    <div class="message"> 
      <div class="message-font" *ngIf="message !== ''"> {{ message}} </div>
      <ul *ngIf="messages.length > 0">
      <li *ngFor="let m of messages">
        {{m}}
      </li>
    </ul>
  </div>  
  <button color="primary" mat-raised-button (click)="close()">Okay</button>
</div>
