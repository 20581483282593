import { WarningDialogModule } from "./components/warning-dialog/warning-dialog.module";
import { BrowserModule } from "@angular/platform-browser";
import { PlatformModule } from "@angular/cdk/platform";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { CdkTableModule } from "@angular/cdk/table";
import { ClickOutsideModule } from "ng-click-outside";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppConfig } from "./app.config";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./components/home/home.component";
import { EditContentDialogComponent } from "./components/administrator/editcontent/editContent-dialog.component";
import { ConfigurationService } from "./configuration.service";
import { DateHelperService } from "./date-helper.service";
import { ModalService } from "./modal.service";
import {
  PriorityClient,
  BillingMethodClient,
  BroadcastQueueClient,
  BroadcastRuleClient,
  BroadcastDestClient,
  EpisodeClient,
  WardClient,
  StatusClient,
  BookableItemClient,
  BookingCenterClient,
  API_BASE_URL,
  HealthFundClient,
  RadiographerClient,
  PracticeClient,
  ProcedureTypeClient,
  BookingClient,
  PatientClient,
  ReferrerAddressClient,
  ReferrerClient,
  ScanTypeClient,
  PersonClient,
  EmrRequestClient,
  ModalityClient,
  TokenClient,
  SettingClient,
  PbrcCustomBillingClient,
  CdnUserClient,
  CdnRoleClient,
  PositionClient,
  HicItemCodeClient,
  ReportClient,
  TimeRestrictionsClient,
  XmlReportClient,
} from "./api.service";
import { HelperService } from "./helper.service";
import { AuthenticationService } from "./authentication.service";
import { MaterialModule } from "./shared/material-components.module";
import { MainModule } from "./components/main/main.module";
import { HttpRequestInterceptor } from "./shared/http.interceptor";
import { ErrorDialogService } from "./components/error-dialog/error-dialog.service";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { ObjectsService } from "./objects.service";
import { AuthorisationService } from "./services/authorisation.service";
import { SettingsService } from "./services/settings.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { InfoDialogModule } from "./components/info-dialog/info-dialog.module";
import { SocketService } from "./services/socket.service";

import {MatMenuModule} from '@angular/material/menu';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function getMerlinRisApiUrl(appConfig: ConfigurationService) {
  return appConfig.apiServer.merlinRisApiUrl;
}

@NgModule({
  declarations: [AppComponent, HomeComponent, EditContentDialogComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    CdkTableModule,
    ClickOutsideModule,
    HttpClientModule,
    MaterialModule,
    MainModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    InfoDialogModule,
    WarningDialogModule,
    PlatformModule,
    MatMenuModule
  ],
  exports: [],
  entryComponents: [EditContentDialogComponent],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    TimeRestrictionsClient,
    ReportClient,
    BroadcastQueueClient,
    BroadcastRuleClient,
    BroadcastDestClient,
    PositionClient,
    CdnRoleClient,
    CdnUserClient,
    AuthenticationService,
    ConfigurationService,
    ModalService,
    EpisodeClient,
    PatientClient,
    WardClient,
    ReferrerAddressClient,
    ReferrerClient,
    EmrRequestClient,
    BillingMethodClient,
    ModalityClient,
    DateHelperService,
    ErrorDialogService,
    BookableItemClient,
    HelperService,
    BookingCenterClient,
    HealthFundClient,
    RadiographerClient,
    PracticeClient,
    ProcedureTypeClient,
    BookingClient,
    StatusClient,
    ScanTypeClient,
    PersonClient,
    PriorityClient,
    TokenClient,
    SettingClient,
    PbrcCustomBillingClient,
    SettingsService,
    AuthorisationService,
    AuthGuardService,
    SocketService,
    ObjectsService,
    HicItemCodeClient,
    XmlReportClient,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useFactory: getMerlinRisApiUrl,
      deps: [ConfigurationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl("/assets/mdi.svg")
    );
  }
}
