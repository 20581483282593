import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material-components.module';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [InfoDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    BrowserModule
  ]
})
export class InfoDialogModule { }
