import { BookingCenterClient, Patient } from './../../api.service';
import { Component } from '@angular/core';
import 'rxjs/Rx';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [trigger(
    'openClose',
    [
      state('collapsed, void', style({ height: '0px' })),
      state('expanded', style({ height: '*' })),
      transition(
        'collapsed <=> expanded', [animate(500, style({})), animate(500)])
    ])]
})
export class HomeComponent {
  title = 'Home page';
  stateExpression: string;

  constructor(private m_BookingCentreService: BookingCenterClient) {
    this.collapse();
  }
  expand() {
    this.stateExpression = 'expanded';
  }
  collapse() {
    this.stateExpression = 'collapsed';
  }
  buttonClicked(): void {
    this.title = 'Button Clicked';
  }
}
