import { Address, BookingCenter, Patient, PatientIdentifier, Unavailability } from './api.service';
import { Injectable } from '@angular/core';
import { TimeSlotHour, TimeSlotMinute } from './date-helper.service';
import { timestamp } from 'rxjs-compat/operator/timestamp';

@Injectable()
export class HelperService {

    getSunday(date: Date){
        while(date.getDay() !== 0){
            date.setDate(date.getDate() - 1);
        }
        return date;
    }

    generateWeekBookingPageTimeSlots(currentDate: Date, p_bookingCentre: BookingCenter,
        globalStart: Date, globalEnd: Date): Array<TimeSlotHour> {
        const timeSlotHours = new Array<TimeSlotHour>();
        const min = new Date(p_bookingCentre.bookableItems[0].weekdayStartsAt).getHours();
        const max = new Date(p_bookingCentre.bookableItems[0].weekdayStopsAt).getHours();

        const timeRestrictions = new Array<Unavailability>();
        p_bookingCentre.bookableItems.forEach(e => {
            if (e.timeRestriction) {
                e.timeRestriction.forEach(f => {
                    timeRestrictions.push(f);
                });
            }
        });

        const upper = globalStart.getHours();
        const lower = globalEnd.getHours();
        const shortestBookingInterval = p_bookingCentre.interval;
        var date = this.getSunday(new Date(currentDate));
        var dayCount = 0;
        while (dayCount < 7){
            for (let j = upper; j <= lower; j++) {

                const timeSlotHour = new TimeSlotHour();
                timeSlotHour.date = new Date(date);
                timeSlotHour.hour = j;
                timeSlotHour.minutes = new Array<TimeSlotMinute>();
                for (let m = 0; m < 60; m += shortestBookingInterval) {
                    const minute = new TimeSlotMinute();
                    minute.hour = timeSlotHour.hour;
                    minute.minute = m;
                    if (min > minute.hour) {
                        minute.afterHours = true;
                    }
                    if (max < minute.hour) {
                        minute.afterHours = true;
                    }

                    timeRestrictions.forEach(ts => {
                        if (this.isRestricted(currentDate, minute.hour, minute.minute, shortestBookingInterval, ts)) {
                            minute.unavailabilityReason = ts.reason;
                        }
                    });

                    timeSlotHour.minutes.push(minute);
                }
                timeSlotHours.push(timeSlotHour);
            }
            dayCount++;
            date.setDate(date.getDate() + 1);           
        }
        return timeSlotHours;
    }
    generateBookingPageTimeSlots(currentDate: Date, p_bookingCentre: BookingCenter,
        globalStart: Date, globalEnd: Date): Array<TimeSlotHour> {
        const timeSlotHours = new Array<TimeSlotHour>();
        const min = new Date(p_bookingCentre.bookableItems[0].weekdayStartsAt).getHours();
        const max = new Date(p_bookingCentre.bookableItems[0].weekdayStopsAt).getHours();

        const timeRestrictions = new Array<Unavailability>();
        p_bookingCentre.bookableItems.forEach(e => {
            if (e.timeRestriction) {
                e.timeRestriction.forEach(f => {
                    timeRestrictions.push(f);
                });
            }
        });

        const upper = globalStart.getHours();
        const lower = globalEnd.getHours();
        const shortestBookingInterval = p_bookingCentre.interval;
        for (let j = upper; j <= lower; j++) {

            const timeSlotHour = new TimeSlotHour();
            timeSlotHour.hour = j;
            timeSlotHour.minutes = new Array<TimeSlotMinute>();
            for (let m = 0; m < 60; m += shortestBookingInterval) {
                const minute = new TimeSlotMinute();
                minute.hour = timeSlotHour.hour;
                minute.minute = m;
                if (min > minute.hour) {
                    minute.afterHours = true;
                }
                if (max < minute.hour) {
                    minute.afterHours = true;
                }

                timeRestrictions.forEach(ts => {
                    if (this.isRestricted(currentDate, minute.hour, minute.minute, shortestBookingInterval, ts)) {
                        minute.unavailabilityReason = ts.reason;
                    }
                });

                timeSlotHour.minutes.push(minute);
            }
            timeSlotHours.push(timeSlotHour);
        }
        return timeSlotHours;
    }

    generateTimeSlots(p_bookingCentre: BookingCenter, globalStart: Date, globalEnd: Date): Array<TimeSlotHour> {
        const timeSlotHours = new Array<TimeSlotHour>();
        const min = new Date(p_bookingCentre.bookableItems[0].weekdayStartsAt).getHours();
        const max = new Date(p_bookingCentre.bookableItems[0].weekdayStopsAt).getHours();

        const upper = globalStart.getHours();
        let lower = globalEnd.getHours();
        lower = 20;
        const shortestBookingInterval = p_bookingCentre.interval;
        for (let j = upper; j <= lower; j++) {

            const timeSlotHour = new TimeSlotHour();
            timeSlotHour.hour = j;
            timeSlotHour.minutes = new Array<TimeSlotMinute>();
            for (let m = 0; m < 60; m += shortestBookingInterval) {
                const minute = new TimeSlotMinute();
                minute.hour = timeSlotHour.hour;
                minute.minute = m;
                if (min > minute.hour) {
                    minute.afterHours = true;
                }
                if (max < minute.hour) {
                    minute.afterHours = true;
                }
                timeSlotHour.minutes.push(minute);
            }
            timeSlotHours.push(timeSlotHour);
        }
        return timeSlotHours;
    }

    stripDate(date: Date) {
        const time = new Date();
        time.setHours(date.getHours());
        time.setMinutes(date.getMinutes());
        time.setSeconds(date.getSeconds());
        time.setMilliseconds(date.getMilliseconds());
        return time;
    }

    compareTimeGreater(date1: Date, date2: Date) {
        const date1time = this.stripDate(date1);
        const date2time = this.stripDate(date2);
        return date1time > date2time;
    }

    compareTimeGreaterEqual(date1: Date, date2: Date) {
        const date1time = this.stripDate(date1);
        const date2time = this.stripDate(date2);
        return date1time >= date2time;
    }

    compareTimeLess(date1: Date, date2: Date) {
        const date1time = this.stripDate(date1);
        const date2time = this.stripDate(date2);
        return date1time < date2time;
    }

    compareTimeLessEqual(date1: Date, date2: Date) {
        const date1time = this.stripDate(date1);
        const date2time = this.stripDate(date2);
        return date1time <= date2time;
    }

    getToday<T>(type: T, day: number): T[keyof T] {
        const casted = day as keyof T;
        return type[casted];
    }

    isRestricted(currentDate: Date, hour: number, minute: number, interval: number, ts: Unavailability) {
        const timeSlotStart = new Date();
        timeSlotStart.setDate(currentDate.getDate());
        timeSlotStart.setHours(hour);
        timeSlotStart.setMinutes(minute);
        timeSlotStart.setSeconds(0);
        timeSlotStart.setMilliseconds(0);
        const timeSlotEnd = new Date(timeSlotStart);
        timeSlotEnd.setMinutes(timeSlotEnd.getMinutes() + interval);
        enum Days {
            SUNDAY,
            MONDAY,
            TUESDAY,
            WEDNESDAY,
            THURSDAY,
            FRIDAY,
            SATURDAYS,
        }
        if (ts.script.isOnce) {
            if ((ts.startsOn >= timeSlotStart && ts.endsOn <= timeSlotEnd)
            || (ts.startsOn <= timeSlotStart && ts.endsOn > timeSlotStart)
            || (ts.startsOn < timeSlotStart && ts.endsOn >= timeSlotEnd)) {
                return true;
            }
        } else if (this.compareTimeGreaterEqual(ts.startsOn, timeSlotStart) &&
            this.compareTimeLessEqual(ts.endsOn, timeSlotEnd)
            ||
            this.compareTimeLessEqual(ts.startsOn, timeSlotStart) &&
            this.compareTimeGreater(ts.endsOn, timeSlotStart)
            ||
            this.compareTimeLess(ts.startsOn, timeSlotStart) &&
            this.compareTimeGreaterEqual(ts.endsOn, timeSlotEnd)) {

            switch (currentDate.getDay()) {
                case Days.SUNDAY:
                    if (ts.script.sun) {
                        return true;
                    }
                    break;
                case Days.MONDAY:
                    if (ts.script.mon) {
                        return true;
                    }
                    break;
                case Days.TUESDAY:
                    if (ts.script.tue) {
                        return true;
                    }
                    break;
                case Days.WEDNESDAY:
                    if (ts.script.wed) {
                        return true;
                    }
                    break;
                case Days.THURSDAY:
                    if (ts.script.thu) {
                        return true;
                    }
                    break;
                case Days.FRIDAY:
                    if (ts.script.fri) {
                        return true;
                    }
                    break;
                case Days.SATURDAYS:
                    if (ts.script.sat) {
                        return true;
                    }
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    camelCaseMe(string) {
        return string.replace(/(?:^\w|\[A-Z\]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    dayDateDiff(date1: Date, date2: Date) {
        const diff = Math.abs(date1.getTime() - date2.getTime());
        return Math.ceil(diff / (1000 * 3600 * 24));
    }
}

export class PatientSearchResult extends Patient {
    patientIdentifier: PatientIdentifier;
    address: Address;
    constructor(public patient: Patient) {
        super();
        this.setPatientIdentifier();
        this.setAddress();
    }

    setPatientIdentifier(): void {
        if (this.patient.patIdentifiers === undefined) {
            this.patient.patIdentifiers = new Array<PatientIdentifier>();
        }

        this.patientIdentifier = this.patient.patIdentifiers?.find(
            (p) =>
                p.patIdentifierType?.toLowerCase() === 'auid' && p.isActive === true
        );
    }

    setAddress(): void {
        this.address =
            this.patient.addresses !== undefined
                ? this.patient.addresses[0]
                : new Address(); // TODO: Choose one address???
    }
}

export class MergePatientResult extends Patient {
    patientIdentifier: PatientIdentifier;
    address: Address;
    action = 'Ignore';
    constructor(public patient: Patient) {
        super();
        this.setPatientIdentifier();
        this.setAddress();
    }

    setPatientIdentifier(): void {
        if (this.patient.patIdentifiers === undefined) {
            this.patient.patIdentifiers = new Array<PatientIdentifier>();
        }

        this.patientIdentifier = this.patient.patIdentifiers?.find(
            (p) =>
                p.patIdentifierType?.toLowerCase() === 'auid' && p.isActive === true
        );
    }

    setAddress(): void {
        this.address =
            this.patient.addresses !== undefined
                ? this.patient.addresses[0]
                : new Address(); // TODO: Choose one address???
    }
}

