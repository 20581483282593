import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SideNavItem } from './side-nav-item.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'cis-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.css']
})
export class SideNavItemComponent implements OnInit {

  @Input('item') item: SideNavItem;

  router: string;

  constructor() { }

  ngOnInit(): void {
    this.router = '/main/' + this.item.router;
  }
}
