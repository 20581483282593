import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  DateAdapter,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconRegistry, MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from "@angular/material/card";
import { ReactiveFormsModule } from "@angular/forms";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
  NGX_MAT_CUSTOM_DATE_FORMATS,
} from "./DateFormat";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ClickOutsideModule } from "ng-click-outside";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { Moment } from "moment";
import { NGX_MAT_DATE_FORMATS } from "@angular-material-components/datetime-picker";

@NgModule({
  imports: [CommonModule],
  exports: [
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSliderModule,
    MatExpansionModule,
    MatTableModule,
    MatTabsModule,
    MatRadioModule,
    MatCardModule,
    MatRippleModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ClickOutsideModule,
    MatSnackBarModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: NGX_MAT_CUSTOM_DATE_FORMATS },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: "end",
        verticalPosition: "bottom",
      },
    },
  ],
})
export class MaterialModule {}
