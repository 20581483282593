import { Injectable } from "@angular/core";
import { SideNavItem } from "./side-nav-item/side-nav-item.interface";
import { Router } from "@angular/router";
import { TokenResponse } from "../../api.service";

@Injectable({
  providedIn: "root",
})
export class SideNavService {
  private _items: Array<SideNavItem>;
  get items(): Array<SideNavItem> {
    return this._items;
  }
  set items(value: Array<SideNavItem>) {
    this._items = value;
  }

  constructor(private _router: Router) {}

  setNavItems(): void {
    this._items = new Array<SideNavItem>();
    // Booking Contents
    const subHeadingBooking = new SideNavItem();
    subHeadingBooking.active = false;
    subHeadingBooking.type = "subheading";
    subHeadingBooking.name = "Booking";
    subHeadingBooking.setClass();

    const itemViewBooking = new SideNavItem();
    itemViewBooking.active = true;
    itemViewBooking.type = "item";
    itemViewBooking.name = "View Bookings";
    itemViewBooking.router = "viewbookings";
    itemViewBooking.setClass();

    const itemSearchBooking = new SideNavItem();
    itemSearchBooking.active = false;
    itemSearchBooking.type = "item";
    itemSearchBooking.name = "Search Bookings";
    itemSearchBooking.router = "searchbookings";
    itemSearchBooking.setClass();

    const itemWaitingRoom = new SideNavItem();
    itemWaitingRoom.active = false;
    itemWaitingRoom.type = "item";
    itemWaitingRoom.name = "Waiting Room";
    itemWaitingRoom.router = "waitingroom";
    itemWaitingRoom.setClass();

    const itemFindTimeSlot = new SideNavItem();
    itemFindTimeSlot.active = false;
    itemFindTimeSlot.type = "item";
    itemFindTimeSlot.name = "Find Time Slot";
    itemFindTimeSlot.router = "findtimeslot";
    itemFindTimeSlot.setClass();

    this._items.push(subHeadingBooking);
    this._items.push(itemViewBooking);
    this._items.push(itemSearchBooking);
    this._items.push(itemWaitingRoom);
    this._items.push(itemFindTimeSlot);
    // Administration
    const subHeadingAdministration = new SideNavItem();
    subHeadingAdministration.active = false;
    subHeadingAdministration.type = "subheading";
    subHeadingAdministration.name = "Administration";
    subHeadingAdministration.setClass();

    const itemHealthFunds = new SideNavItem();
    itemHealthFunds.active = false;
    itemHealthFunds.type = "item";
    itemHealthFunds.name = "Health Funds";
    itemHealthFunds.router = "health-funds";
    itemHealthFunds.setClass();

    const itemPatient = new SideNavItem();
    itemPatient.type = "item";
    itemPatient.active = false;
    itemPatient.name = "Patient";
    itemPatient.router = "patient";
    itemPatient.setClass();

    const itemPatientMerge = new SideNavItem();
    itemPatientMerge.type = "item";
    itemPatientMerge.active = false;
    itemPatientMerge.name = "Patient Merge";
    itemPatientMerge.router = "patient-merge";
    itemPatientMerge.setClass();

    const timeRestrictionsMerge = new SideNavItem();
    timeRestrictionsMerge.type = "item";
    timeRestrictionsMerge.active = false;
    timeRestrictionsMerge.name = "Time Restrictions";
    timeRestrictionsMerge.router = "time-restrictions";
    timeRestrictionsMerge.setClass();

    const itemPractice = new SideNavItem();
    itemPractice.type = "item";
    itemPractice.active = false;
    itemPractice.name = "Practice";
    itemPractice.router = "practice";
    itemPractice.setClass();

    const itemRoom = new SideNavItem();
    itemRoom.type = "item";
    itemRoom.active = false;
    itemRoom.name = "Room";
    itemRoom.router = "room";
    itemRoom.setClass();

    const itemProcedureType = new SideNavItem();
    itemProcedureType.type = "item";
    itemProcedureType.active = false;
    itemProcedureType.name = "Procedure Type";
    itemProcedureType.router = "proceduretype";
    itemProcedureType.setClass();

    const itemWard = new SideNavItem();
    itemWard.type = "item";
    itemWard.active = false;
    itemWard.name = "Ward";
    itemWard.router = "ward";
    itemWard.setClass();

    const itemReferrer = new SideNavItem();
    itemReferrer.type = "item";
    itemReferrer.active = false;
    itemReferrer.name = "Referrer";
    itemReferrer.router = "referrer";
    itemReferrer.setClass();

    const itemPBRC = new SideNavItem();
    itemPBRC.type = "item";
    itemPBRC.active = false;
    itemPBRC.name = "PBRC Custom Billing";
    itemPBRC.router = "pbrc";
    itemPBRC.setClass();

    const itemPerson = new SideNavItem();
    itemPerson.type = "item";
    itemPerson.active = false;
    itemPerson.name = "Person";
    itemPerson.router = "person";
    itemPerson.setClass();

    const itemModality = new SideNavItem();
    itemModality.type = "item";
    itemModality.active = false;
    itemModality.name = "Modality";
    itemModality.router = "modality";
    itemModality.setClass();

    const itemUser = new SideNavItem();
    itemUser.type = "item";
    itemUser.active = false;
    itemUser.name = "User";
    itemUser.router = "user";
    itemUser.setClass();

    const itemRadiographer = new SideNavItem();
    itemRadiographer.type = "item";
    itemRadiographer.active = false;
    itemRadiographer.name = "Radiographer";
    itemRadiographer.router = "radiographer";
    itemRadiographer.setClass();

    const itemScanType = new SideNavItem();
    itemScanType.type = "item";
    itemScanType.active = false;
    itemScanType.name = "Scan Type";
    itemScanType.router = "scantype";
    itemScanType.setClass();

    this._items.push(subHeadingAdministration);
    if (
      (JSON.parse(localStorage.getItem("auth-info")) as TokenResponse).role ===
      "System Administrator"
    ) {
      this._items.push(itemHealthFunds);
      this._items.push(itemRadiographer);
      this._items.push(itemReferrer);
      this._items.push(itemScanType);
      this._items.push(itemWard);
      this._items.push(itemProcedureType);
      this._items.push(itemPBRC);
      this._items.push(itemPerson);
      this._items.push(itemPatient);
      this._items.push(itemRoom);
      this._items.push(itemModality);
      this._items.push(timeRestrictionsMerge);
      this._items.push(itemPractice);
      this._items.push(itemPatientMerge);
      this._items.push(itemUser);
    } else if (
      (JSON.parse(localStorage.getItem("auth-info")) as TokenResponse).role ===
      "Practice Administrator"
    ) {
      this._items.push(itemReferrer);
      this._items.push(itemPatient);
      this._items.push(itemRadiographer);
      this._items.push(itemScanType);
      this._items.push(timeRestrictionsMerge);
    } else {
      this._items.push(itemReferrer);
      this._items.push(itemPatient);
      this._items.push(timeRestrictionsMerge);
    }

    // System
    const subHeadingSystem = new SideNavItem();
    subHeadingSystem.active = false;
    subHeadingSystem.type = "subheading";
    subHeadingSystem.name = "System Utility";
    subHeadingSystem.setClass();
    this._items.push(subHeadingSystem);
    const itemBroadcastRules = new SideNavItem();

    itemBroadcastRules.type = "item";
    itemBroadcastRules.active = false;
    itemBroadcastRules.name = "Broadcast Rules";
    itemBroadcastRules.router = "broadcastrules";
    itemBroadcastRules.setClass();

    const itemReports = new SideNavItem();
    itemReports.type = "item";
    itemReports.active = false;
    itemReports.name = "Reports";
    itemReports.router = "reports";
    itemReports.setClass();

    const itemConfiguration = new SideNavItem();
    itemConfiguration.type = "item";
    itemConfiguration.active = false;
    itemConfiguration.name = "Configuration";
    itemConfiguration.router = "configuration";
    itemConfiguration.setClass();

    const itemSiteSettings = new SideNavItem();
    itemSiteSettings.type = "item";
    itemSiteSettings.active = false;
    itemSiteSettings.name = "Site Settings";
    itemSiteSettings.router = "sitesettings";
    itemSiteSettings.setClass();

    const itemBroadcastQue = new SideNavItem();
    itemBroadcastQue.type = "item";
    itemBroadcastQue.active = false;
    itemBroadcastQue.name = "Broadcast Queue";
    itemBroadcastQue.router = "broadcastque";
    itemBroadcastQue.setClass();

    const itemBroadcastDest = new SideNavItem();
    itemBroadcastDest.type = "item";
    itemBroadcastDest.active = false;
    itemBroadcastDest.name = "Broadcast Dests";
    itemBroadcastDest.router = "broadcastdest";
    itemBroadcastDest.setClass();

    const itemResendBilling = new SideNavItem();
    itemResendBilling.type = "item";
    itemResendBilling.active = false;
    itemResendBilling.name = "Resend Billing";
    itemResendBilling.router = "resendbilling";
    itemResendBilling.setClass();
    if (
      (JSON.parse(localStorage.getItem("auth-info")) as TokenResponse).role ===
      "System Administrator"
    ) {
      this._items.push(itemBroadcastRules);
      this._items.push(itemBroadcastDest);
      this._items.push(itemBroadcastQue);
      this._items.push(itemReports);
      this._items.push(itemConfiguration);
      this._items.push(itemSiteSettings);
      this._items.push(itemResendBilling);
    }
    if (
      (JSON.parse(localStorage.getItem("auth-info")) as TokenResponse).role ===
      "Practice Administrator"
    ) {
      this._items.push(itemBroadcastQue);
      this._items.push(itemResendBilling);
    }
  }
}
