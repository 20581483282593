import { ObjectsService } from './../../objects.service';
import { SettingsService } from './../../services/settings.service';
import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { UserMenuComponent } from '../usermenu/usermenu.component';
import { Router } from '@angular/router';
import { TokenResponse } from '../../api.service';
import { AuthenticationService } from '../../authentication.service';
import {ToggleService} from '../../services/toggle.service';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})

export class MainComponent implements OnInit {
  @ViewChild('userMenu', { static: true }) userMenu: UserMenuComponent;
  @ViewChild("sidenav") sidenav: ElementRef;
 
  settingsLoaded: boolean;
  need_toggle: boolean= true;  //for mat-nav sidebar

  constructor(private m_settingService: SettingsService, private m_objectService: ObjectsService,
    private m_authService: AuthenticationService, private router: Router, private status:ToggleService) {
    router.events.subscribe((val) => {
      this.m_authService.checkExternal();
    });
    
    //subscribe
  }

 

  async ngOnInit() {
    this.m_objectService.preload();
    this.settingsLoaded = false;
    await this.m_settingService.getSettings().toPromise();
    this.settingsLoaded = true;
  }

  onUserMenuItemChanged(value: string) {
    this.router.navigateByUrl('/main/' + value);
  }

  update_toggle(){
    this.need_toggle=this.status.getData(); //from service
  }
  
}
