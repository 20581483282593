import { SettingClient, TokenResponse } from "./../../api.service";
import { Component, OnInit } from "@angular/core";
import { SideNavItem } from "./side-nav-item/side-nav-item.interface";
import { SideNavService } from "./side-nav.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../authentication.service";
import "rxjs/add/operator/filter";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "cis-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.css"],
})
export class SideNavComponent implements OnInit {
  enabled = true;
  private routesToExclude = [];

  private m_currentUser: any;
  get currentUser(): any {
    return this.m_currentUser;
  }
  set currentUser(value: any) {
    this.m_currentUser = value;
  }

  items: Array<SideNavItem>;

  constructor(
    private _sidNavService: SideNavService,
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {
    this._sidNavService.setNavItems();
    this.items = this._sidNavService.items;
    this.enabled = this.enableByRoute();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(
      localStorage.getItem("auth-info")
    ) as TokenResponse;
  }

  enableByRoute() {
    let enabled = true;
    this.routesToExclude.forEach((route) => {
      if (this._router.url.startsWith(route)) {
        enabled = false;
      }
    });
    return enabled;
  }

  logout(): void {
    this._authenticationService.logout();
    this._router.navigate(["/login"]);
  }
}
