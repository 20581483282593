import { Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { OpenBooking } from '../extensions';

@Injectable()
export class SocketService {
    public bookingHub: signalR.HubConnection;
    public openBookings: Array<OpenBooking> = [];

    constructor() { }

    bookingOpen(kyBooking, kyPractice) {
        if (kyBooking && kyPractice) {
            if (this.bookingHub?.state !== HubConnectionState.Disconnected) {
                this.bookingHub.send('BookingOpen', kyBooking, kyPractice);
            }
        }
    }

    bookingClosed(kyBooking, kyPractice) {
        if (kyBooking && kyPractice) {
            if (this.bookingHub?.state !== HubConnectionState.Disconnected) {
                this.bookingHub.send('BookingClosed', kyBooking, kyPractice);
            }
        }
    }

    getUsersForBooking(kyBooking) {
        return this.openBookings.filter(e => e.KyBooking === kyBooking).map(e => { return e.User })
    }
}
