import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'usermenu',
  templateUrl: './usermenu.component.html'
  // styleUrls: ['./usermenu.component.css']
})

export class UserMenuComponent {
  @Output() menuItemChanged = new EventEmitter();


  private m_currentUser: any;
  get currentUser(): any {
    return this.m_currentUser;
  }
  set currentUser(value: any) {
    this.m_currentUser = value;
  }

  private m_menuItem: string;
  get menuItem(): string {
    return this.m_menuItem;
  }
  set menuItem(value: string) {
    this.m_menuItem = value;
  }

  constructor(private m_authenticationService: AuthenticationService, private router: Router) {
  }

  setMenuItem(value: string) {
    this.menuItemChanged.emit(value);
  }

  logout() {
    this.m_authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
