export class SideNavItem {
  name: string;
  icon?: string;
  type?: 'item' | 'subheading';
  active: boolean;
  customClass?: string;
  router?: string;
  action?: string;

  setClass(): void {
    this.customClass = this.active ? 'activeSideNavItem' : 'inactiveSideNavItem';

  }
}
