<div class="sidenav" fxLayout="column" *ngIf="enabled">
  <ngx-simplebar class="bar sidenav-items" fxFlex="auto">
    <div class="sidenav-header" fxLayout="row" fxLayoutAlign="center center">
        <!-- <img src="assets/image/MerlinRIS_SVG.svg" style="border: none; width:100%;" />  -->
        <h2 class="logo-text">MerlinRIS</h2>
    </div>
    <div class="sidenav-user" fxLayout="row" fxLayoutAlign="center center">
      <!-- Margin corresponding MerlinRIS logo -->
      <p>{{ currentUser.username }}</p>
      <a mat-icon-button (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
      </a>
    </div>
    <cis-side-nav-item
      *ngFor="let item of items"
      [item]="item"
    ></cis-side-nav-item>
  </ngx-simplebar>
</div>
