<div class="container">
  <mat-sidenav-container autosize fxFlex >
    <mat-sidenav mode="side" [opened]="need_toggle" [ngStyle]="{display:update_toggle()}">
      <cis-side-nav></cis-side-nav>
    </mat-sidenav>
    <mat-sidenav-content class="content-container" fxLayout="column">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
